<template>
<div class="course">

  <v-card-title class="custom-link" @click="back" >{{$t('back')}}</v-card-title>

  <v-card-title>{{$t('course')}}: {{this.course.title}}</v-card-title>
  <div id="course-info" v-if="isLearndash">

    <template style="padding: 10px">
      <v-row>
        <v-col>
          <div v-html="courseDescription"></div>
          <ul class="float-list float">
            <li v-if="showEventListIcon"  class="item float-item" @click="openEventList()">
              <template>
                <v-row justify="space-around">
                  <div style="cursor: pointer">
                    <v-icon
                        large
                    >
                      mdi-calendar-today
                    </v-icon>
                    <div style="">Termine</div>
                  </div>
                </v-row>
              </template>
            </li>
            <li v-if="showUsers" class="item float-item" @click="openUsers()">
              <template>
                <v-row justify="space-around">
                  <div style="cursor: pointer">
                    <v-icon
                        large
                    >
                      mdi-account-group
                    </v-icon>
                    <div style="">Teilnehmer</div>
                  </div>
                </v-row>
              </template>
            </li>
            <li v-if="showDownloads" class="item float-item" @click="openDownloadList()">

              <template>
                <v-row justify="space-around">
                  <div style="cursor: pointer">
                    <v-icon
                        large
                    >
                      mdi-download
                    </v-icon>
                    <div style="">Downloads</div>
                  </div>
                </v-row>
              </template>
            </li>
            <li v-if="showChat" class="item float-item" @click="openChat()">
              <div style="clear: both;"></div>
              <template style="cursor: pointer">
                <v-row justify="space-around">
                  <div style="cursor: pointer">
                    <v-icon
                        large
                    >
                      mdi-newspaper
                    </v-icon>
                    <div style="">Chat</div>
                  </div>
                </v-row>
              </template>
            </li>
          </ul>
        </v-col>
        <v-col>
          <v-img max-width="300" :src="this.course.imagePath"></v-img>
        </v-col>
      </v-row>
    </template>


<!--
    <div style="padding: 10px;clear: both"></div>
    <v-card-title v-if="showDownloads">{{$t('nav.downloads')}}</v-card-title>
    <v-data-table
        v-if="showDownloads"
        :headers="headers"
        :items="course.downloads"
        :items-per-page="5"
        class="elevation-1"
        :hide-default-footer="course.downloads.length < 5"
        @click:row="itemSelected"
    >
      <template v-slot:item:name="{  }">
        <v-avatar
            color="primary"
            size="56"
        ></v-avatar>
      </template>
      <template v-slot:item.author="{ item }">
        <v-avatar
            class="mr-1"
            style="color: white; font-weight: bolder;"
            color="warning"
            size="35"
            small
        >{{uppercaseFirstLetter(item.author.firstName)}}{{uppercaseFirstLetter(item.author.lastName)}}</v-avatar>
        {{item.author.firstName}} {{item.author.lastName}}
      </template>
      <template v-slot:item.status="{ item }" class="ml-0">
        <v-chip
            :color="item.status === 'draft' ?  'secondary' : 'success'"
            class="ma-0"
            small
            style="width: 80px"
        >
          {{item.status}}
        </v-chip>
      </template>
      <template v-slot:item.updatedAt="{item}" class="ml-0">
        {{item.updatedAt}}
      </template>

    </v-data-table>
    -->
  </div>

  <!-- NAVIGATION -->
  <div class="main-content">
    <!--
    <v-container v-if="showInSiteNav" class="course-menu" style="margin:0; max-width: none;">
      <v-row>
        <v-col style="max-width: 800px">
          <v-list max-width="400">
            <template v-for="item in course.chapters">

              <v-list-item :class="setMenuActiveClass(item.chapter)" :id="item.chapter.id" :ref="item.chapter.id" :disabled="item.chapter.inactive" v-if="(!item.chapter.lessons || !(Array.isArray(item.chapter.lessons)) || item.chapter.lessons.length == 0)" :key="item.chapter.title" @click="menuItemClick(item.chapter, 'chapter')">
                <v-list-item-content>
                  <v-list-item-title v-text="getItemTitle(item)" />
                </v-list-item-content>
              </v-list-item>
              <v-list-group
                  v-else
                  :key="item.chapter.title"
                  v-model="item.chapter.active"
                  :prepend-icon="item.chapter.icon"
                  no-action
                  :disabled="item.chapter.inactive"
                  :class="item.chapter.id+' course-menu-item'"
                  @click="menuItemClick(item.chapter, 'chapter', true)"
              >
                <template :disabled="item.chapter.inactive" v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="getItemTitle(item)"></v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                    v-for="child in item.chapter.lessons"
                    :key="child.title"
                    :disabled="item.chapter.inactive"
                    @click="menuItemClick(child, 'lesson', false)"
                    :class="setMenuActiveClass(child.lesson)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="getItemTitle(child)"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </template>
          </v-list>
        </v-col>
        <v-col style="width: 100%">
          <h3>{{lessonTitle}}</h3>
          <div id="content" style="padding-top: 20px;">
            <div v-html='lessonHTML'></div>
            <a v-show="showLink" style="color:blue;" :href="pdfLink" target="_blank">{{pdfTitle}}</a>
          </div>

        </v-col>

      </v-row>
    </v-container>
    -->
  </div>
  <div v-if="showEntireNav">
    <v-btn
        v-if="showBurger"
        color="#FFCF6C"
        big
        fixed
        top
        left
        fab
        style=""
        class="overlay-burger main-burger"
        v-on:click="toggleNav()"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <v-navigation-drawer
        v-model="drawer"
        clipped
        app
        width="100%"
        class="course-menu"
        style=""
        id="overlay-navigation"
        @transitionend="navigationTransitioned"
    >
      <template style="z-index:100;" v-slot:prepend>
        <div style="z-index: 100;width: 100%;height: 20px;margin-top:10px;"><v-card-title style="color:black;margin-left:15px;" class="title">{{ $t("Course Content") }}</v-card-title><v-icon  @click="toggleNav();" style="position:absolute; right: 10px;top:15px;cursor: pointer;">mdi-close</v-icon></div>
        </template>
        <v-list height="100%">
          <template v-for="item in course.chapters">
            <v-list-item :class="setMenuActiveClass(item.chapter)" :id="item.chapter.id" :disabled="item.chapter.inactive" v-if="(!item.chapter.lessons || !(Array.isArray(item.chapter.lessons)) || item.chapter.lessons.length == 0)" :key="item.chapter.link" @click="menuItemClick(item, 'chapter', false)">
              <v-icon v-if="item.complete" class="completedIcon">mdi-check-bold</v-icon>
              <v-list-item-content>
                <v-list-item-title v-text="getItemTitle(item)" />
              </v-list-item-content>
            </v-list-item>
            <v-list-group
                v-else
                :key="item.chapter.title"
                v-model="item.chapter.active"
                :prepend-icon="item.chapter.icon"
                no-action
                @click="menuItemClick(item, 'chapter', true)"
                :class="item.chapter.id+' course-menu-item'"
            >
              <template :disabled="item.chapter.inactive" v-slot:activator>

                <v-list-item-content>
                  <v-list-item-title v-text="getItemTitle(item)"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                  v-for="child in item.chapter.lessons"
                  :key="child.title"
                  :disabled="item.chapter.inactive"
                  @click="menuItemClick(child, 'lesson', false)"
                  :class="setMenuActiveClass(child.lesson)"
              >
                <v-icon v-if="child.complete" class="completedIcon">mdi-check-bold</v-icon>
                <v-list-item-content>

                  <v-list-item-title v-text="getItemTitle(child)"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list>
    </v-navigation-drawer>
  </div>
  <v-overlay :value="overlay" :opacity="opacity" style="overflow-y: scroll">
    <v-row style="padding: 10px;background-color: rgba(0,0,0,0.6);">
      <div style="max-width: 33%;padding-left:60px">{{this.course.title}}</div>
      <div class="overlay_button_group">
        <div v-if="showCompleteButton" style="text-align: center; margin-bottom:5px;"><v-btn color="#005AA9" @click="markComplete">{{ $t("Completed") }}</v-btn></div>
        <div v-if="showCompleted"><v-icon class="completedIcon">mdi-check-bold</v-icon></div>
        <v-icon v-if="showEntireNav"  @click="prevPage();" style="height: 20px;cursor: pointer;">mdi-chevron-left</v-icon>
        <v-icon v-if="showEntireNav"  @click="nextPage();" style="height: 20px;cursor: pointer;">mdi-chevron-right</v-icon>
        <v-icon  @click="hideOverlay();" style="height: 20px;cursor: pointer;">mdi-close</v-icon>
      </div>
    </v-row>
    <v-row id="overlayContentRow"  @scroll="handleScroll" style="margin-bottom: 0px;border: 0px;height:100%;">
      <v-col id="overlayContent" :style="{width: showiFrame ? '100%' : 'calc(100% - 410px)'}">
        <iframe v-if="showiFrame" v-on:load="hideActivityIndicator" :src="iFrameURL" style="margin-bottom: 10px;border: 0px;height:calc(100% - 50px);" width="100%"></iframe>
        <activity-indicator v-if="showActivityIndicator" size="large" color="blue" :about="absolute" style="position: fixed; left:50%; top: 50%;" />

        <SCROMPlayer v-if="showSCORMPlayer" :scormurl="scormURL" :userid="userid" :courseid="this.course.id" ></SCROMPlayer>
        <div class="pdf-download" v-if="pdfDownload" style="text-align: right;"><a :href="pdfUrl" target="_blank">PDF herunterladen</a></div>
        <!--
        <PDFViewer ref="pdfViewer" v-if="showPDFViewer" :url="pdfUrl" :downloadable="pdfDownload" style="margin-bottom: 10px;border: 0px;height:calc(100% - 50px);"></PDFViewer>
        <SFPDFViewer id="sfpdf" ref="sfPDFViewer" v-if="showSFPDFViewer" :url="pdfUrl" :downloadable="pdfDownload" style=""></SFPDFViewer>
        -->
        <WebViewer v-if="showPDFViewer" :url="pdfUrl" :downloadable="pdfDownload" :path="`${publicPath}lib`"/>

        <VideoPlayer style="max-width: 100%" v-if="showVideoPlayer" :url="videoUrl"></VideoPlayer>

        <EventList v-if="showEventList" :course="course"></EventList>

        <DownloadList v-if="showDownloadList" :course="course"></DownloadList>

        <UserList v-if="showUserList" :course="course"></UserList>

      </v-col>
    </v-row>
  </v-overlay>
</div>
</template>

<script>

//import APIService from "@/services/APIService";
import EventBus from "@/services/EventBus";
//import PDFViewer from "@/views/PDFViewer";
//import SFPDFViewer from "@/views/SFPDFViewer";
import VideoPlayer from "@/views/VideoPlayer";
import shared from "@/services/shared";
import SCROMPlayer from "@/views/SCROMPlayer";
import {mapState} from "vuex";
import APIService from "@/services/APIService";
import EventList from "@/views/EventList";
import UserList from "@/views/UserList";
import WebViewer from "@/components/WebViewer";
import DownloadList from "@/views/DownloadList";
//import pdfvuer from "pdfvuer";
const UPLOAD_PATH = process.env.VUE_APP_ROOT_API+"/v1/content/";
export default {
  name: 'Course',
  components: {DownloadList, WebViewer, UserList, EventList, SCROMPlayer, VideoPlayer},
  data () {
    return {
      courseActive: true,
      publicPath: process.env.BASE_URL,
      course: {},
      courseUsers: {},
      completedEvents: [],
      currentItem: null,
      courseType: "regular",
      items: [],
      instance: "demo",
      lessonTitle: "",
      lessonHTML: "",
      lessonStartDate: "",
      pdfTitle: "PDF Titel",
      showFrame: true,
      showLink: false,
      showOverlayMenu: false,
      showActivityIndicator: false,
      showiFrame: false,
      showPDFViewer: false,
      showSFPDFViewer: false,
      showWebViewer: false,
      showChat: false,
      showUsers: false,
      showDownloads: false,
      showDownloadList: false,
      showEventListIcon: false,
      pdfDownload: false,
      pdfUrl: "",
      showVideoPlayer: false,
      videoUrl: "",
      showSCORMPlayer: false,
      showEventList: false,
      showUserList: false,
      pdfLink: "",
      frameURL: "",
      iFrameURL: "",
      scormURL: "",
      isLearndash: true,
      isCourseOverlay: true,
      overlay: false,
      absolute: true,
      opacity: 0.9,
      downloads: [],
      courseDescription: "",
      showCompleteButton: false,
      showCompleted: false,
      currentItemID: 0,
      currentItemType: "",
      currentChapterId: "",
      currentLessonId: "",
      fromID: false,
      data_base_url: 'https://api.backend.digital.stuck-komzet.de/v1/content',
      menuHeight:"400px",
      menuScroll:'scroll',
      fixedOverlayHeight: false,
      activeMenuID: "",
      drawer: null,
      showBurger: true,
      showEntireNav: true,
      userid: 0,
      showInSiteNav: false,
      courseCompleted: false
    }
  },
  beforeRouteLeave (to, from, next) {
    this.courseActive = false;
    if(this.courseType != "demo") {
      this.userStoppedLearning();
    }
    next();
  },
  async mounted() {
    this.courseActive = true;

    if(this.course === "[object Object]"){
      this.$router.push({name: 'courses', params:{ }} )
      return;
    }

    this.courseDescription = this.course.description;

    if(window.innerWidth > 1263) {
      this.showBurger = false;
    }

    await this.fetchData(false);

    this.filterDates(this.course);

    if(this.courseType != "demo"){
      APIService.sendEvent('openCourseEvent', '', this.course.id);
      this.userStartedLearning();
    }

    this.prepareLayout();

    if(this.instance == "all"){
      this.course = await APIService.getCourse(this.course.id);
    }

    EventBus.$emit('showTopImage', false);
    if(this.courseType != "demo"){
      this.userid = this.user.id;
      //console.log("userid: "+this.userid);
    }
    EventBus.$on('windowSizeChanged', (width, height) => {
      this.setMenuHeight(height);
      this.setMenuHeight(height);
    });
    EventBus.$on('lessonFinished', () => {
      this.markComplete();
    });
    this.setMenuHeight(window.innerHeight);

  },
  async created() {
    this.instance = this.$route.params.instance
    let course_tmp = this.$route.params.course
    if (
        typeof course_tmp === 'object' &&
        !Array.isArray(course_tmp) &&
        course_tmp !== null
    ) {
      this.fromID = false;
      this.course = course_tmp;
    } else {
      this.fromID = true;
      this.course = await APIService.getCourse(course_tmp);
    }
    this.filterDates(this.course);

    this.navigationTransitioned();

    EventBus.$on('userInactive', (value) => {
      if(this.courseActive) {
        if (value) {
          this.userStoppedLearning();
        } else {
          this.userStartedLearning();
        }
      }
    });
    EventBus.$on('userLeaves', () => {
      if(this.courseActive){
        this.userStoppedLearning();
      }

    });
  },
  computed: {
    noChildren: function (item) {
      return (!item.content || item.content.length == 0 || !item.content[0].title);
    },
    headers() { return [
      {
        text: this.$t('title'),
        align: 'start',
        sortable: true,
        value: 'title',
        width: 400
      },
      {
        text: this.$t('size'),
        value: 'size',
        sortable: true
      },
      {
        text: this.$t('date'),
        value: 'updatedAt',
        sortable: true
      }
    ]},
    ...mapState(['user']),
  },
  methods: {

    userStoppedLearning(){
      APIService.sendEvent("closedCourseEvent", "", this.course.Id)
      if(this.currentChapterId != null && this.currentChapterId != "") {
        APIService.sendEvent("closedChapterEvent", "", this.course.Id, this.currentChapterId);
        if(this.currentLessonId != null && this.currentLessonId != ""){
          APIService.sendEvent("closedLessonEvent", "", this.course.Id, this.currentChapterId, this.currentLessonId);
        }
      }
    },
    userStartedLearning(){
      if(this.currentChapterId != null && this.currentChapterId != "") {
        APIService.sendEvent("openChapterEvent", "", this.course.Id, this.currentChapterId);
        if(this.currentLessonId != null && this.currentLessonId != ""){
          APIService.sendEvent("openLessonEvent", "", this.course.Id, this.currentChapterId, this.currentLessonId);
        }
      }
    },
    prepareLayout(){
      this.showUsers = false;
      this.showChat = false;
      this.showEventListIcon = false;
      this.showDownloads = false;
      if(this.course.extensions){
        if(this.course.extensions.showFellowStudents){
          this.showUsers = true;
        }
        if(this.course.extensions.chat){
          this.showChat = true;
        }
        if(this.course.extensions.events){
          this.showEventListIcon = true;
        }
        if(this.course.extensions.uploads){
          this.showDownloads = true;
        }
      }
    },
    navigationTransitioned(){
      //console.log("transitioned "+this.drawer);
      if(this.drawer){
        this.showBurger = false;
      }else{
        this.showBurger = true;
      }
    },
    toggleNav(){
      this.drawer = !this.drawer;
    },
    setMenuActiveClass: function(item){

      if(item == null){
        return ' course-menu-item';
      }

      let classList = item.id+' course-menu-item'
      if(this.activeMenuID == item.id){
        classList += " menu-active";
      }
      return classList;
    },
    setMenuHeight: function(height){
      this.menuHeight = height-100+"px";
    },
    openEventList: function(){
      this.openOverlay("", false, false, false,'eventList');
    },
    openDownloadList: function(){
      this.openOverlay("", false, false, false,'downloadList');
    },
    openChat: function(){
      let token = shared.encodeToken(this.user);
      //let users = shared.encodeToken(this.courseUsers);
      let chat_url = "https://www.stuck-komzet.de/chat/"+this.course.id+"/?cid="+this.course.id+"&token=";
      chat_url = chat_url + token+":";
      window.open(chat_url);
    },
    openUsers: function(){
      this.openOverlay("", null, false, false, "userList")
    },
    markComplete: function(){
      //console.log("set item with id "+this.currentItemID+" and type "+this.currentItemType+" complete for user "+this.user.id);
      if(this.courseType != "demo") {
        if (this.currentItemType == "chapter") {
          APIService.sendEvent("chapterCompletedEvent", "1", this.course.id, this.currentChapterId);
          APIService.sendStudyProgress(this.course.id, this.currentChapterId, "", true);
        } else if (this.currentItemType == "lesson") {
          APIService.sendEvent("lessonCompletedEvent", "1", this.course.id, this.currentChapterId, this.currentLessonId);
          APIService.sendStudyProgress(this.course.id, this.currentChapterId, this.currentLessonId, true);
        }

      }
      this.showCompleted = true;
      this.showCompleteButton = false;
      this.fetchData(true);
    },
    handleScroll (e) {
      EventBus.$emit('overlayScrolled', e.target.scrollTop);
    },
    getItemTitle: function(item){
      let title = "";
      let item_title = "";

      if(item.chapter) {
        item_title = item.chapter.title;
        if (item.chapter.inactive && item.chapter.startDateShort && item.chapter.startDateShort != "") {
          title = "ab " + item.chapter.startDateShort + " - ";
        }
      }else if(item.lesson){
        item_title = item.lesson.title;

      }else{
        item_title = item.title;
      }

      if(item.aliasTitle && item.aliasTitle != ''){
        return title + item.aliasTitle;
      }else{

        return title + shared.trimTitle(item_title);
      }
    },
    hideActivityIndicator(){
      this.showActivityIndicator = false;
    },
    toggleOverlayMenu(){
      this.showOverlayMenu = !this.showOverlayMenu;
    },
    openLink(url, overlay = false, addParameters = false){
      if(overlay){
        this.openOverlay(url, true, false,"link");
      }else{
        if(addParameters){
          url = url+"?user=stark&pass=Vctr.5844&fromapp=1&hideCookieBanner=1&userSSO="+this.user.email;
        }
      }

    },
    openURL(url, popup = false) {
      shared.goToLink(url, popup);
    },
    itemSelected(item){
      window.open(item.content);
    },
    setMenuBackground(menuID){
      let all = document.getElementsByClassName('course-menu-item');
      for(let i=0; i<all.length; i++){
        all[i].classList.remove('menu-active');
      }
      let els = document.getElementsByClassName(menuID);
      for(let i=0; i < els.length; i++){
        els[i].classList += " menu-active";
      }
    },
    nextPage(){
      if(this.currentItem.nextItem != null){
        this.menuItemClick(this.currentItem.nextItem, this.currentItem.nextItem.type, this.currentItem.nextItem.isGroup, 1);
      }

    },
    prevPage(){
      if(this.currentItem.prevItem != null){
        this.menuItemClick(this.currentItem.prevItem, this.currentItem.prevItem.type, this.currentItem.prevItem.isGroup, -1);
      }
    },
    menuItemClick(item, type, isGroup, prevNext = 0){
      if(this.currentItem == item){
        return;
      }
      if(this.courseType != "demo") {
        this.userStoppedLearning();
      }

      if(!isGroup && (this.overlay == true || window.innerWidth < 960)){
        this.drawer = false;
      }
      this.lessonTitle = this.getItemTitle(item);
      this.lessonHTML = item.description;
      this.showFrame = false;
      this.showLink = false;
      this.currentItem = item;
      this.currentItemID = item.id;
      this.currentItemType = type;
      this.currentLessonId = "";
      if(type == "chapter" && item.chapter.inactive){
        return;
      }

      if(type == "lesson"){
        item = item.lesson;
        this.currentLessonId = item.id;
      }else if(type == "chapter"){
        item = item.chapter;
        this.currentChapterId = item.id;
      }
      if(this.courseType != "demo") {
        this.userStartedLearning();
      }
      this.showCompleted = false;
      this.showCompleteButton = true;
      this.completedEvents.forEach((value) => {
          if(value.completionDate && (value.chapter == item.id || value.lesson == item.id)){
            this.showCompleteButton = false;
            this.showCompleted = true;
          }
        });


      this.activeMenuID = item.id;
      this.setMenuBackground(item.id);

      if(type == 'chapter' || type == 'lesson'){

        if(type == 'lesson' && item && item.courseLink && item.courseLink != "") {
          this.openOverlay(item.courseLink, item, true, true);
        }else if(type == 'chapter' && item && item.courseLink && item.courseLink != ""){
          this.openOverlay(item.courseLink, item, true, true);
        }else {
          if (item && item.content && item.content.length > 0 && item.content[0] != "") {

            let type = "";
            let url = UPLOAD_PATH + item.content[0].path;
            if (url.endsWith("pdf")) {
              type = "pdf";
            } else if (url.endsWith("mp4")) {
              type = "video";
            } else if (item.content[0].path != "") {
              type = "scorm";
              //url += "/index_lms.html";
            } else {
              url += "/index.html";
            }
            this.openOverlay(url, item, false, true, type);
          }else{
            if(prevNext == 1){
              this.nextPage();
            }else if(prevNext == -1){
              this.prevPage();
            }
          }
        }

        if(type == "chapter" && item && item.lessons && item.lessons.length > 0){
          this.showOverlayMenu = true;
        }
        return;
      }
      switch(item.type){
        case 'pdf':{
          this.pdfTitle = "PDF ansehen";
          this.pdfLink = item.url;
          this.showLink = true;
          break;
        }
        case 'html-demo':{

          if(this.isLearndash){
            this.openOverlay(item.content[0], item, true, true);
          }else{
            this.openOverlay(item.content[0], item, false, true);
          }
          break;
        }
      }

    },
    openOverlay(url, item, addParameters, showBurger = false, type = ""){

      this.showEventList = false;
      this.showDownloadList = false;
      this.showiFrame = false;
      this.showSCORMPlayer = false;
      this.showUserList = false;
      this.showPDFViewer = false;
      this.showSFPDFViewer = false;
      this.showWebViewer = false;
      this.showVideoPlayer = false;
      this.showActivityIndicator = true;
      this.showCompletedButton = true;
      //this.showBurger = showBurger;
      this.showEntireNav = true;
      this.isCourseOverlay = showBurger;

      this.fixedOverlayHeight = false;

      this.pdfDownload = false;
      if(type == "test"){
        this.pdfDownload = true;
        this.overlay = true;
        this.showActivityIndicator = false;
        return;
      }

      if(this.courseType == "demo"){
        this.showCompleteButton = false;
      }

      if(type=="pdf" || type =="pdf-download") {

        /* eslint-disable no-constant-condition */
        /*
        if(item.title == "TS Test Neuer Viewer") {
          this.showSFPDFViewer = true;
        }else if(item.title == "TS Test Neuer Viewer 2"){
          this.showWebViewer = true;
        }else{
          this.showPDFViewer = true;
        }*/
        this.showPDFViewer = true;
        this.pdfUrl = url;
        //this.pdfUrl = "https://www.trigital.de/tmp/cors/beispiel.pdf";
        if(item.isContentDownloadable){
          this.pdfDownload = true;
        }
        this.showActivityIndicator = false;
      }else if(type=="video") {
        this.videoUrl = url;
        this.showVideoPlayer = true;
        this.showActivityIndicator = false;
      }else if(type=="scorm") {
        this.fixedOverlayHeight = true;
        this.showSCORMPlayer = true;
        this.showActivityIndicator = false;
        this.scormURL = url;
      }else if(type == "eventList"){
        this.showEventList = true;
        this.showActivityIndicator = false;
        this.showEntireNav = false;
      }else if(type == "downloadList"){
        this.showDownloadList = true;
        this.showActivityIndicator = false;
        this.showEntireNav = false;
      }else if(type == "userList"){
        this.showUserList = true;
        this.showActivityIndicator = false;
        this.drawer = false;
        this.showEntireNav = false;
      }else{
        if(addParameters){
          url = url+"?fromapp=1&hideCookieBox=1&cid="+this.course.id;
          if(this.courseType != "demo"){
            let token = shared.encodeToken(this.user);
            //let users = shared.encodeToken(this.courseUsers);
            url = url + "&token="+token;
          }else{
            url = url + "&userSSO=demo";
          }
        }
        if(type != "link"){
          url += "&login_from_sso=1&openCourse=1";

        }
        this.showiFrame = true;
        this.iFrameURL = url;
        //console.log(type+" with parameters?: "+url);
      }

      this.overlay = true;
      this.drawer = false;
      EventBus.$emit('overlayContentChanged', {"url": url, "type": type});
      EventBus.$emit('hideMenuEvent', 'hide');

    },
    hideOverlay() {
      this.showiFrame = false;
      this.showPDFViewer = false;
      this.showVideoPlayer = false;
      this.overlay = false;
      this.showOverlayMenu = false;
      this.showActivityIndicator = false;
      this.drawer = true;
      this.showEntireNav = true;
      EventBus.$emit('hideMenuEvent', 'show');
    },
    back() {
      this.$router.back();
    },
    filterDates: function(course){
      for(let i=course.chapters.length-1; i>=0; i--){
        let c = course.chapters[i];
        c.chapter.inactive = false;
        let newDate;
        if(course.startDate && course.startDate != ""){
          let startDate = new Date(course.startDate);
          let offset = c.startOffsetDays;
          newDate = new Date(startDate.valueOf());
          newDate.setDate(newDate.getDate() + offset);

        }else if(c.startDate){
          newDate = new Date(c.startDate);
        }else{
          continue;
        }
        let now = new Date();

        c.chapter.startDate = newDate.toLocaleString('de-DE');
        let options = {  day: 'numeric', month: 'short' };

        c.chapter.startDateShort = newDate.toLocaleString('de-DE', options)

        if(now < newDate){
          c.chapter.inactive = true;
          //course.chapters.splice(i, 1);
        }
      }

      return course;
    },
    setItemsCompleted(){
      shared.setItemsCompleted(this.course, this.completedEvents);
      /*
      console.log("set items completed");
      let item = shared.getCourseFirstItem(this.course);
      console.log("first item");
      console.log(item);
      if(item != null){
        if(shared.checkItemCompleted(item, this.completedEvents)){
          console.log("first item is completed");
          item.complete = true;
        }else{
          console.log("first item is not completed");
          item.complete = false;
        }
        while(item.nextItem != null){
          item = item.nextItem;
          if(shared.checkItemCompleted(item, this.completedEvents)){
            console.log("item is completed");
            item.complete = true;
          }else{
            console.log("item is not comleted");
            item.complete = false;
          }
        }
      }*/
    },
    async fetchData(reloadStudyProgress) {


      if(this.courseType != "demo") {
        this.courseUsers = await APIService.getCourseUsers(this.course.id);
        //let lessonCompletedEvents = (await APIService.getUserEvents( this.user.id, "lessonCompletedEvent")).results;
        //this.completdEvents = (await APIService.getUserEvents( this.user.id, "chapterCompletedEvent")).results;
        //Array.prototype.push.apply(this.completdEvents, lessonCompletedEvents);

        if(reloadStudyProgress) {
          //let studyProgress = await APIService.getStudyProgress(this.course.id);
          let studyProgress = await APIService.getStudyProgressUser(this.userid);
          if (studyProgress != null) {
            this.completedEvents = [];
            studyProgress.forEach((prog) => {
              if(prog.course == this.course.id){
                this.completedEvents.push(prog);
              }
            });
          }
        }else{
          this.completedEvents = this.course.studyProgress;
        }
        this.completedEvents.forEach((value) => {
          if(!value.chapter && !value.lesson){
            this.courseCompleted = true;
          }
        });
      }

      shared.addNextPreviousItemsToCourseItems(this.course);

      if(this.courseType != "demo" && !this.courseCompleted){
        this.courseCompleted = shared.checkCourseCompleted(this.course, this.completedEvents);
        if(this.courseCompleted){
          await APIService.sendStudyProgress(this.course.id, "", "", true, false);
        }
      }
      this.setItemsCompleted();
    },
  },
  async beforeRouteEnter(to, from, next) {
    let c = {};
    c = to.params.course;


    next(next(vm => (vm.course = c, vm.courseType = to.params.courseType)));
  },
  async beforeRouteUpdate(to,from,next) {
    this.instance = to.params.instance;
    this.courseType = to.params.courseType;
    this.course = to.params.course;

    next(vm => vm.course = to.params.course)
  }

}

</script>

<style>

  .float-list {
    list-style:none;
    margin: 0;
    padding: 0;
  }
  .item {
    padding: 5px;
    width: 100px;
    height: 60px;
    margin: 10px;

    line-height: 20px;
    padding: 20px;
    color: black;
    font-weight: bold;
    text-align: center;
  }

    /*float layout*/
    .float {
      max-width: 1200px;
      margin: 0 auto;
    }
    .float:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
    .float-item {
      float: left;
    }

    /*tables*/
  .theme--light.v-data-table{
    color:white;
    background-color: transparent;
  }
  .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    color:white !important;
  }
  .theme--light.v-data-table .v-icon.v-icon{
    color:white !important;;
  }
  .theme--light.v-data-table .v-data-table-header th.sortable:hover, .theme--light.v-data-table .v-data-table-header th.sortable.active{
    color:black;
  }
  .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    color:black;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
    padding-left:10px;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
    padding-left: 10px;
  }

  .v-overlay__content{
    width: 100%;
    padding: 10px;
  }
  .v-overlay__content{
    height: 100%;
  }

  .main-content{
    margin-top:20px;
    margin-top:20px;
  }
 #overLayMenuRow{
   overflow-y: scroll;
   overflow-x: hidden;
   width: 100%;
 }
 #overlayContent{
  background-color: rgba(0,0,0,0.6);
 }
 #overlayContent > div:first-of-type{
   height: calc(100% - 10px);
 }
  #overlayContent iframe:first-of-type{
    height: 100%;
  }
</style>
