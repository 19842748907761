<template>
  <div>
  <iframe src="" style="border:0px #ffffff none;" id="iframescorm" name="myiFrame" height="650px" width="100%" type="text/html"

          allowfullscreen="allowfullscreen" webkitallowfullscreen="webkitallowfullscreen"
          mozallowfullscreen="mozallowfullscreen"></iframe>
  </div>
</template>

<script>

import EventBus from "@/services/EventBus";
//import axios from "axios";
//import {XMLParser} from "fast-xml-parser";
import shared from "@/services/shared";

export default {
  name: "SCROMPlayer",
  props: ['scormurl', 'userid', 'courseid'],
  data() {
    return {
      scorm_base_url: 'https://api.backend.digital.stuck-komzet.de/v1/content/scorm.html?url=',
      self1: this
    }
  },

  async mounted() {
    this.openNewURL(this.scormurl);
    EventBus.$on('overlayContentChanged', (args) => {
      if(args.type != "scorm") return;
      this.openNewURL(args.url);
    });
  },
  methods:{
    openNewURL: async function(url){
      let file = await shared.getFilenameByManifest(url);
      console.log(url);
      let newurl = this.scorm_base_url+url+file+"&userId="+this.userid+"&courseId="+this.courseid;
      console.log(newurl);
      document.getElementById("iframescorm").setAttribute("src", newurl);
    },
    receiveiFrameMessage: function (event){
      const message = event.data.message;

      switch (message) {
        case 'LMSCommited':
          console.log("Vue LMSCommited: ");
          console.log(event.data.value);
          if(event.data.value.core.lesson_status == "passed"){
            EventBus.$emit('lessonFinished', true);
          }
          break;
        case 'LMSFinished':
          console.log("Vue LMSFinished: ");
          console.log(event.data.value);
          console.log(event.data.value.core.lesson_status);
          if(event.data.value.core.lesson_status == "passed"){
            EventBus.$emit('lessonFinished', true);
          }

          //alert("Kurs "+event.data.value.course_id+" abgeschlossen durch "+event.data.value.core.student_id+": "+event.data.value.core.lesson_status);
          break;
      }
    }
  },
  created: function() {
    window.addEventListener("message", this.receiveiFrameMessage, false);
  },
  destroyed: function () {
    window.removeEventListener("message", this.receiveiFrameMessage)
  }
}

</script>

<style scoped>

</style>
