<template>
  <div class="block galleryBlock">
    <v-container>
      <v-row>
        <v-col v-for="item in items" :key="item.id" class="child-flex" cols="12" sm="6" xs="12" md="4" lg="3" xl="2">
          <v-card @click="itemSelected(item)" flat tile width="320px">
            <v-img :src="item.imagePath" aspect-ratio="1" class="grey lighten-2">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-card-text class="white--text" style="margin-top:5px;" >{{item.title}}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "TileGrid.vue",
  props: ['items', 'type'],
  data() {
    return {

    };
  },
  mounted() {
    //console.log(this.items);
    //console.log(this.type);
  },
  methods:{
    itemSelected: function(item){
      console.log("rtype in tile: "+this.type);
      this.$router.push({name: 'course', params:{ course: item, courseType: this.type }} )
    }
  }

}
</script>

<style scoped>
  @media all and (max-width: 600px) {
    .v-card {
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
